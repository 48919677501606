.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    justify-content: center;
  }
  
  .image-container {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

  }
  
  .image-container img {
    width: 100%;
    height: 200px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image covers the area while maintaining aspect ratio */
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .image-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    h3 {
      color: #fff;
    }
  }
  
  .image-container img:hover {
    transform: scale(1.05);
  }
  
  .image-container h3 {
    text-align: center;
    margin-top: 10px;
    color: #b9b9b9;
    font-family: 'Arial', sans-serif;
    font-size: 1.2em;
    
  }
  
  .section-container h2 {
    text-align: center;
    color: #fff;
    font-family: 'Arial', sans-serif;
    font-size: 2em;
    margin-bottom: 20px;
  }